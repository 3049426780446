$(function () {  
    $(window).scroll(function() {  
        var sticky = $('.navbar'),
          scroll = $(window).scrollTop();
         
        if (scroll >= 40) { 
          sticky.addClass('fixed'); }
        else { 
         sticky.removeClass('fixed');
      
      }
      });
      // $('select').selectpicker();

//       const signUpButton = document.getElementById('signUp');
// const signInButton = document.getElementById('signIn');
// const container = document.getElementById('container');

// signUpButton.addEventListener('click', () => container.classList.add('right-panel-active'));

// signInButton.addEventListener('click', () => container.classList.remove('right-panel-active'));

// const signUpButton = document.getElementById('signUp');
// const signInButton = document.getElementById('signIn');
// const container = document.getElementById('container');
// if(signUpButton !=null){
//   signUpButton.addEventListener('click', () => container.classList.add('right-panel-active'));
// }else{}
// if(signInButton !=null){
//   signInButton.addEventListener('click', () => container.classList.remove('right-panel-active'));
// }


  //   $('body').bind('contextmenu', function(e){
  //       return false; }); 
   });

function openNav() {
  document.getElementById("mySidenav").style.width = "200px";
}

function closeNav() {
  document.getElementById("mySidenav").style.width = "0";
}

